.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
}

.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background: #f1f1f1;
  border-radius: 50%;
  opacity: 0.5;
  animation: rise 200s infinite ease-in; /* 10s * 20 = 200s */
}

.bubble:nth-child(1) {
  width: 40px;
  height: 40px;
  left: 10%;
  animation-duration: 160s; /* 8s * 20 = 160s */
}

.bubble:nth-child(2) {
  width: 20px;
  height: 20px;
  left: 20%;
  animation-duration: 100s; /* 5s * 20 = 100s */
  animation-delay: 20s; /* 1s * 20 = 20s */
}

.bubble:nth-child(3) {
  width: 50px;
  height: 50px;
  left: 35%;
  animation-duration: 140s; /* 7s * 20 = 140s */
  animation-delay: 40s; /* 2s * 20 = 40s */
}

.bubble:nth-child(4) {
  width: 80px;
  height: 80px;
  left: 50%;
  animation-duration: 220s; /* 11s * 20 = 220s */
}

.bubble:nth-child(5) {
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 120s; /* 6s * 20 = 120s */
  animation-delay: 20s; /* 1s * 20 = 20s */
}

.bubble:nth-child(6) {
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 160s; /* 8s * 20 = 160s */
  animation-delay: 60s; /* 3s * 20 = 60s */
}

.bubble:nth-child(7) {
  width: 90px;
  height: 90px;
  left: 70%;
  animation-duration: 240s; /* 12s * 20 = 240s */
  animation-delay: 40s; /* 2s * 20 = 40s */
}

.bubble:nth-child(8) {
  width: 25px;
  height: 25px;
  left: 80%;
  animation-duration: 120s; /* 6s * 20 = 120s */
  animation-delay: 40s; /* 2s * 20 = 40s */
}

.bubble:nth-child(9) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-duration: 100s; /* 5s * 20 = 100s */
  animation-delay: 20s; /* 1s * 20 = 20s */
}

.bubble:nth-child(10) {
  width: 90px;
  height: 90px;
  left: 25%;
  animation-duration: 200s; /* 10s * 20 = 200s */
  animation-delay: 80s; /* 4s * 20 = 80s */
}

@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(100px);
  }
  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}

