

  .positive {
    background: rgb(25, 135, 84);
    border-radius: 5px;
    padding: 5px;
    margin: -5px;

    margin-left: -2px;
    padding-bottom: 1px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .negative {
    background: rgb(220, 53, 69);
    border-radius: 5px;
    padding: 5px;
    margin: -5px;

    margin-left: -2px;
    padding-bottom: 1px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .neutral {
    background: rgb(13, 110, 253);
    border-radius: 5px;
    padding: 5px;
    margin: -5px;

    margin-left: -1px;
    padding-bottom: 1px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .todo {
    background: #444;
    border-radius: 5px;
    padding: 5px;
    margin: -5px;

    margin-left: -1px;
    padding-bottom: 1px;
    padding-right: 2px;
    padding-left: 2px;
  }
  .empty {
    color: transparent;
  }
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;  
  }
  
  .highlighted-words {
    position: absolute;
    top: 0;
    padding-top: 10px;
    padding-left: 10px;
    left: 0;
    color: transparent;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  
  .text-field {
    color: white;
    background-color: transparent;
    border: none;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    z-index: 10;
  }
  
  


  form {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }

  form div {
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }

  input,
  select,
  button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  input:focus,
  select:focus,
  button:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #0056b3;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }